import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PageSection from "../components/page-section"
import TextSection from "../components/text-section"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page not found" />

    <PageSection content={{ heading: '404: Page not found' }} >
      <TextSection>
        <p>
          You just hit a route that doesn&#39;t exist. Go back to the <Link to="/">home page</Link>.
        </p>
      </TextSection>
    </PageSection>
  </Layout>
)

export default NotFoundPage
